<template>
  <v-data-table
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="
      guaranteePackageStatusRequest.value === 'loading-getGuaranteePackagees'
    "
    loading-text="Đang tải dữ liệu"
    :items="guaranteePackages"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.time`]="{ item }">
      {{ item.time + " tháng" }}
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <div class="my-1" v-html="item.description"></div>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <div class="d_flex justify-center alight-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            class="mr-2"
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="viewDetail(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="deleteGuaranteePackage(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    selectedBrands: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Mã gói BH",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên gói bảo hành",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Thời hạn bảo hành",
          align: "start",
          sortable: false,
          value: "time"
        },
        {
          text: "Mô tả",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    guaranteePackages() {
      return this.$store.getters["GUARANTEE_PACKAGE/guaranteePackages"];
    },
    guaranteePackageStatusRequest() {
      return this.$store.getters["GUARANTEE_PACKAGE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async deleteGuaranteePackage(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa gói bảo hành <strong>"${item.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch(
                "GUARANTEE_PACKAGE/deleteGuaranteePackage",
                {
                  data: item.id,
                  routeQuery: {
                    search: routeQuery.search || null,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                }
              );

              if (
                this.guaranteePackageStatusRequest.value ===
                "success-deleteGuaranteePackage"
              ) {
                this.$modal.hide({ name: "modal-guaranteePackage" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              } else {
                if (
                  this.guaranteePackageStatusRequest.value ===
                  "error-deleteGuaranteePackage"
                ) {
                  this.$modal.hide({ name: "modal-guaranteePackage" });
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Lỗi xóa gói bảo hành :("
                    }
                  });
                }
              }
            }
          }
        }
      });
    },

    getIndexOfGuaranteePackage(id) {
      const arr = this.guaranteePackages.map(item => item.id);

      return arr.indexOf(id);
    },

    async viewDetail(item) {
      await this.$store.dispatch("GUARANTEE_PACKAGE/setGuaranteePackage", {
        ...item
      });
      // Open modal
      this.$modal.show({
        name: "modal-guarantee-package",
        payload: {
          index: this.getIndexOfGuaranteePackage(item.id)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
