<template>
  <tp-modal max-width="768px" name="modal-guarantee-package" width="70%">
    <v-card flat>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          {{ guaranteePackage.id ? "Sửa gói bảo hành" : "Thêm gói bảo hành" }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="guaranteePackage.id"
          :loading="
            guaranteePackageStatusRequest.value ===
              'loading-updateGuaranteePackage'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="
            guaranteePackageStatusRequest.value ===
            'loading-updateGuaranteePackage'
              ? null
              : updateGuaranteePackage()
          "
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          :loading="
            guaranteePackageStatusRequest.value ===
              'loading-createGuaranteePackage'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="
            guaranteePackageStatusRequest.value ===
            'loading-createGuaranteePackage'
              ? null
              : createGuaranteePackage()
          "
        >
          Thêm
        </v-btn>
        <v-btn
          v-if="guaranteePackage.id"
          color="red accent-1"
          icon
          @click="deleteGuaranteePackage()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="px-5 pt-5" flat>
          <v-row class="">
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Tên gói bảo hành</div>
              <v-text-field
                v-model="guaranteePackage.name"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập tên chi nhánh"
                single-line
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="6" sm="12">
              <div class="font-weight-bold mb-2">Mã gói bảo hành</div>
              <v-text-field
                v-model="guaranteePackage.code"
                v-mask="'XXXXXXXXX'"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập mã gói"
                single-line
              ></v-text-field>
            </v-col>
            <v-col class="py-0" md="6" sm="12">
              <div class="font-weight-bold mb-2">Thời hạn</div>
              <v-text-field
                v-model="guaranteePackage.time"
                class="text-body-1"
                dense
                outlined
                placeholder="Nhập thời hạn"
                single-line
                suffix="tháng"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12">
              <div class="font-weight-bold mb-2">Mô tả ngắn</div>
              <ThinkTiptap
                v-model="guaranteePackage.description"
                :config="config"
                class="tp-tiptap"
              ></ThinkTiptap>
            </v-col>
            <v-col class="py-0 mb-5" cols="12">
              <div class="font-weight-bold mb-2">Mô tả chi tiết</div>
              <ThinkTiptap
                v-model="guaranteePackage.notes"
                :config="config"
                class="tp-tiptap"
              ></ThinkTiptap>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      config: {
        imageUploadFolder: "editor",
        sites: ["THINKPRO", "GOODSPACE"]
      }
    };
  },
  computed: {
    guaranteePackage() {
      return this.$store.getters["GUARANTEE_PACKAGE/guaranteePackage"];
    },
    guaranteePackageStatusRequest() {
      return this.$store.getters["GUARANTEE_PACKAGE/statusRequest"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-guarantee-package"
      });
      this.$store.dispatch("GUARANTEE_PACKAGE/resetGuaranteePackage");
      this.activeTab = null;
    },

    async createGuaranteePackage() {
      const routeQuery = this.$route.query;
      // Request create
      await this.$store.dispatch("GUARANTEE_PACKAGE/createGuaranteePackage", {
        data: this.guaranteePackage,
        routeQuery: {
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.guaranteePackageStatusRequest.value ===
        "success-createGuaranteePackage"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm gói bảo hành thành công"
          }
        });
      }
    },

    async deleteGuaranteePackage() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa gói bảo hành <strong>"${this.guaranteePackage.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch(
                "GUARANTEE_PACKAGE/deleteGuaranteePackage",
                {
                  data: this.guaranteePackage.id,
                  routeQuery: {
                    search: routeQuery.search || null,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                }
              );

              if (
                this.guaranteePackageStatusRequest.value ===
                "success-deleteGuaranteePackage"
              ) {
                this.$modal.hide({ name: "modal-guarantee-package" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              } else {
                if (
                  this.guaranteePackageStatusRequest.value ===
                  "error-deleteGuaranteePackage"
                ) {
                  this.$modal.hide({ name: "modal-guarantee-package" });
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Lỗi xóa gói bảo hành :("
                    }
                  });
                }
              }
            }
          }
        }
      });
    },

    async updateGuaranteePackage() {
      const routeQuery = this.$route.query;
      // Request update
      await this.$store.dispatch("GUARANTEE_PACKAGE/updateGuaranteePackage", {
        data: this.guaranteePackage,
        routeQuery: {
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (
        this.guaranteePackageStatusRequest.value ===
        "success-updateGuaranteePackage"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật gói bảo hành thành công"
          }
        });
      }
    }
  }
};
</script>
